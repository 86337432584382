<template>
<div>
    <UserList></UserList>
</div>
</template>

<script>
import UserList from './UserList'

export default {
  components: {
	  UserList
  },
  methods:{
  }
}
</script>
